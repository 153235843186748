var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-5"},[_c('v-toolbar',[_c('v-toolbar-title',[_vm._v("Cov-Cares Stoplight Survey")]),_c('v-spacer'),_c('span',{staticStyle:{"min-width":"100px","text-align":"right"}},[_vm._v(_vm._s(_vm.termLabel))])],1),(_vm.loading)?_c('v-alert',{attrs:{"type":"info"}},[_vm._v("Loading surveys...")]):(_vm.courses.length === 0 && _vm.placements.length === 0)?_c('v-alert',{attrs:{"type":"error"}},[_vm._v("No surveys were found. We could not find any courses or ScotsWork placements assigned to you.")]):_c('v-row',{staticClass:"mt-2"},[(_vm.courses.length > 0)?_c('v-col',{attrs:{"cols":12,"md":_vm.placements.length > 0 ? 6 : 10,"offset-md":_vm.placements.length > 0 ? 0 : 1,"lg":_vm.placements.length > 0 ? 6 : 8,"offset-lg":_vm.placements.length > 0 ? 0 : 2,"offset-xl":_vm.placements.length > 0 ? 0 : 3,"xl":"6"}},[_c('v-card',[_c('v-list',[(_vm.placements.length > 0)?_c('v-subheader',[_vm._v("Courses")]):_vm._e(),_vm._l((_vm.courses),function(ref){
var _id = ref._id;
var title = ref.title;
var studentCount = ref.studentCount;
var surveyDate = ref.surveyDate;
return _c('v-list-item',{key:_id,staticClass:"survey-item",attrs:{"to":'/cov-cares/stoplight/' + _id}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(title))]),_c('v-list-item-subtitle',[_vm._v("Students: "+_vm._s(studentCount))]),(surveyDate)?_c('v-list-item-subtitle',[_vm._v("Survey completed: "+_vm._s(_vm.stringFormatDate(surveyDate)))]):_c('v-list-item-subtitle',[_vm._v("No Stoplight Survey Submitted")])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":surveyDate ? 'success' : 'info'}},on),[_vm._v("fa fa-"+_vm._s(surveyDate ? 'check' : 'exclamation')+"-circle")])]}}],null,true)},[(surveyDate)?_c('span',[_vm._v("Survey completed on "+_vm._s(_vm.stringFormatDate(surveyDate)))]):_c('span',[_vm._v("No Stoplight Survey Submitted")])])],1)],1)})],2)],1)],1):_vm._e(),(_vm.placements.length > 0)?_c('v-col',{attrs:{"cols":12,"md":_vm.courses.length > 0 ? 6 : 10,"offset-md":_vm.courses.length > 0 ? 0 : 1,"lg":_vm.courses.length > 0 ? 6 : 8,"offset-lg":_vm.courses.length > 0 ? 0 : 2,"offset-xl":_vm.courses.length > 0 ? 0 : 3,"xl":"6"}},[_c('v-card',[_c('v-list',[(_vm.courses.length > 0)?_c('v-subheader',[_vm._v("ScotsWork Placements")]):_vm._e(),_vm._l((_vm.placements),function(ref){
var _id = ref._id;
var title = ref.title;
var studentCount = ref.studentCount;
var surveyDate = ref.surveyDate;
return _c('v-list-item',{key:_id,staticClass:"survey-item",attrs:{"to":'/cov-cares/stoplight/' + _id}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(title))]),_c('v-list-item-subtitle',[_vm._v("Students: "+_vm._s(studentCount))]),(surveyDate)?_c('v-list-item-subtitle',[_vm._v("Survey completed: "+_vm._s(_vm.stringFormatDate(surveyDate)))]):_c('v-list-item-subtitle',[_vm._v("No Stoplight Survey Submitted")])],1),_c('v-list-item-action',[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"color":surveyDate ? 'success' : 'info'}},on),[_vm._v("fa fa-"+_vm._s(surveyDate ? 'check' : 'exclamation')+"-circle")])]}}],null,true)},[(surveyDate)?_c('span',[_vm._v("Survey completed on "+_vm._s(_vm.stringFormatDate(surveyDate)))]):_c('span',[_vm._v("No Stoplight Survey Submitted")])])],1)],1)})],2)],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }